<template>
    <div id="tutorial" class="frontpage">
        <HeaderTitle :title="'Tutorial Penggunaan Platform Infinity'" :subtitle="''" />
        <section>
            <div class="container">
                <!-- <div class="col-12 py-5">
                    <center>
                        <h2 class="title">Apa Yang Ingin Anda Tanyakan?</h2>
                        <div class="search_bar mb-5">
                            <Icon icon="bx:bx-search" />
                            <input type="text" class="search-input" placeholder="Cari Tutorial ...">
                            <button type="button" class="btn btn-primary">Search</button>
                        </div>
                    </center>
                    <p class="subtitle text-black mb-2">Hal yang sering ditanyakan</p><hr>
                    <div class="col-12 col-md-10 mt-4">
                        <div class="accordion transparent-accord" role="tablist">
                            <div v-for="index in 3" :key="index">
                                <b-card-header header-tag="header" class="py-3" role="tab">
                                    <p block v-b-toggle="'accordion-' + index">
                                        gravida hac sagittis adipiscing feugiat bibendum adipiscing
                                        <Icon icon="dashicons:arrow-down-alt2" class="arrow-down ml-2" />
                                        <Icon icon="dashicons:arrow-up-alt2" class="arrow-up ml-2" />
                                    </p>
                                </b-card-header>
                                <b-collapse :id="'accordion-'+ index" visible accordion="my-accordion" role="tabpanel">
                                    <b-card-body>
                                        <b-card-text>{{ text }}</b-card-text>
                                    </b-card-body>
                                </b-collapse>
                            </div>
                        </div>
                    </div>
                </div> -->
                <div class="row py-5 px-4">
                    <div class="col-lg-6 col-md-12 mb-4">
                        <div class="card p-3">
                            <h2 class="fw-600 mb-4 fs-24">Tutorial Infinity Mahasiswa</h2>
                            <iframe :src="pdf_mahasiswa" frameborder="0" style="height: 800px;"></iframe>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12 mb-4">
                        <div class="card p-3">
                            <h2 class="fw-600 mb-4 fs-24">Tutorial Skripsi</h2>
                            <iframe :src="pdf_skripsi" frameborder="0" style="height: 800px;"></iframe>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
    import HeaderTitle from '@/components/HeaderTitle'

    export default {
        name: 'Tutorial',
        components: {
            HeaderTitle
        },
        data () {
            return {
                pdf_mahasiswa: process.env.VUE_APP_STORAGE_API_URL + 'mahasiswa.pdf',
                pdf_skripsi: process.env.VUE_APP_STORAGE_API_URL + 'skripsi_mahasiswa.pdf',
                text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut luctus tincidunt ipsum tempus viverra sit. Porttitor aliquam tempor, malesuada nulla curabitur consequat lacus aliquam. Rhoncus et elementum, arcu pharetra, nullam sit faucibus. '
            }
        }
    }
</script>
